// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 100vh;
	max-height: 480px;

	@include media-breakpoint-up(sm) {
		max-height: 80vh;
	}
}

// header-badge
div.header-badge {
	position: absolute;
	z-index: 3;
	bottom: -70px;
	right: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 141px;
	height: 142px;
	padding: 20px;
	background: url('/images/badge.svg');
	color: $white;
	font-size: 14px;
	line-height: 1.2;
	text-align: center;
	font-family: $font-family-secondary;

	a {
		color: $white;
		text-decoration: none;

		&:hover {
			color: $gray;
		}
	}

	@include media-breakpoint-down(lg) {
		top: 30px;
		bottom: auto;
	}
}

.owl-carousel {
	.item {
		align-items: flex-end;
		.owl-caption {
			margin-bottom: 8rem;
			.owl-title {
				font-family: $font-family-tertiary;
				font-size: 5rem;
				color: $white;
				position: relative;
				z-index: 2;

				@include media-breakpoint-down(sm) {
					font-size: 2.5rem;
				}
			}

			.owl-description {
				img {
					width: auto;
					position: absolute;
					z-index: 1;
					bottom: -60px;
					left: 20px;

					@include media-breakpoint-down(lg) {
						bottom: -30px;
						left: 14px;
					}
				}
			}
		}
	}
	.owl-nav {
		.owl-prev { left: 18px; }
		.owl-next { right: 18px; }
	}
}
