.card {
    padding: 1.25rem;
    text-align: left;
    border: none;

    .card-body {
        padding: 10px 10px 20px;

        .card-caption {
            display: flex;
            flex-flow: column wrap;

            .card-subtitle {
                order: -1;
                margin-bottom: .5rem;
                margin-top: 0;
                font-size: $h3-font-size;
            }

            .card-title-link {
                margin-bottom: 0;
            }
            .card-title {
                color: $primary;
            }

        }
    }

    &:not(.icon) {
        // for card corners style
        @include inner-round-shape($white);
    }

    // icon card
    &.icon {
        box-shadow: none;
        background-color: unset;
        border: none;
        padding: 0;
        .card-image {
            background-image: url('/images/icon-bg.svg');
            background-size: contain;
            background-repeat: no-repeat;
            display: flex;
            justify-content: center;
            align-items: center;
            aspect-ratio: 1;
            font-size: $h3-font-size;
            color: $white;
            
            @include media-breakpoint-up(sm) {
                font-size: $h1-font-size;
            }
        }

        .card-body {
            padding-top: 5px;
            @include media-breakpoint-up(sm) {
                padding: 15px 10px 0;
            }
            .card-title {
                color: $green-dark;
                font-size: $small-font-size;
                text-align: center;
                
                @include media-breakpoint-up(sm) {
                    font-size: $font-size-base;
                }
            }

            .card-buttons {
                display: none;
            }
        }
    }
    
    // .default card
    &.default {
        .card-body {
            padding: 10px 0 0;
        }
    }
}