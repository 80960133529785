.collection.grid {

    .grid-items {
        .item {
            filter: drop-shadow(2px 4px 6px rgba($black, 0.3));
        }
    }

    &.icons-collection {
        .grid-items {
            @include media-breakpoint-down(sm) {
                padding-right: 15px;
                padding-left: 15px;
            }
            .item {
                @include make-col(4);
                filter: none;
                margin-bottom: 1rem;
                
                @include media-breakpoint-down(sm) {
                    padding-right: 9px;
                    padding-left: 9px;
                }

                @include media-breakpoint-up(lg) {
                    max-width: 20%;
                    flex: 0 0 20%;
                }
            }
        }
    }

    &.collection-two-columns {
        .grid-items {
            .item {
                @include media-breakpoint-up(lg) {
                    @include make-col(6);
                }
                margin-bottom: 1.25rem;
            }
        }
    }

    &.photo-collection {
        .card {
             // for card corners style
            @include inner-round-shape($green);
            
            .card-body {
                .card-caption {
                    .card-title {
                        font-size: $h4-font-size;
                        color: $white;
                    }
                }
            }
        }
    }
}