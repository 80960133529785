// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors
$green: #7AA787;
$green-dark: #478588;
$gray: #5C7087;
$orange: #E94E0F;
$beige: #F5F4E9;
$yellow: #FAF6C5;
$white: #fff;
$black: #000;

// theme-colors
$primary: $green-dark;
$secondary: $orange;

// body
$body-bg: $white;
$body-color: $black;
$section-bg: $beige;

// links
$link-color: $primary;
$link-decoration: underline;
$link-hover-color: darken($link-color, 50%);
// typography
// @import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap'); // Great Vibes
// @import url('https://fonts.googleapis.com/css2?family=Calistoga&display=swap'); // Calistoga
// @import url('https://fonts.googleapis.com/css2?family=Damion&display=swap'); // Damion
// @import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@400;700&display=swap'); // Be Vietnam Pro

$font-family-primary: 'Be Vietnam Pro', sans-serif;
$font-family-secondary: 'Calistoga', serif;
// $font-family-tertiary: 'Great Vibes', cursive;
$font-family-tertiary: 'Damion', cursive;

$font-family-base: $font-family-primary;

$font-size-base: 1rem; // 16px

$h1-font-size: $font-size-base * 3; // 48px
$h2-font-size: $font-size-base * 2.25; // 36px
$h3-font-size: $font-size-base * 1.875; // 30px
$h4-font-size: $font-size-base * 1.5; // 24px
$h5-font-size: $font-size-base * 1.25; // 20px
$h6-font-size: $font-size-base; // 16px

$small-font-size: $font-size-base * 0.75; // 12px

$headings-font-family: $font-family-secondary;
$headings-font-weight: 700;
$headings-color: $black;

// contextual
$light: $green-dark;
$dark: $gray;
$text-light: $white;
$text-dark: $black;

// matrix-colors
$matrix-color-primary: $primary;
$matrix-color-secondary: $secondary;

// shadow
$shadow: 6px 6px 12px rgba($black, 0.29);

// border-radius
$border-radius: 6px;

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;
*/

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1170px,
) !default;
