.faq-overview {
    padding: 20px;
    text-align: left;
    @include media-breakpoint-down(sm) {
		@include inner-round-shape($white, calc(0% + 30px), calc(100% - 30px));
	}

	@include media-breakpoint-up(md) {
		@include inner-round-shape($white, calc(0% + 30px), calc(100% - 30px), '/images/blocktop.svg', '/images/blockbottom.svg');
	}

    .faq-category {
        .faq-items {
            .faq-item {
                .faq-question {
                    h3 {
                        color: $primary;
                    }
                }
            }
        }
    }
}