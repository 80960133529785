// mini-sab
.mini-sab {
	.container {
		position: relative;
		// @extend .d-flex;
		// @extend .justify-content-center;

		@include media-breakpoint-between(md, lg) {
			max-width: 720px;
		}

		.container-holder {
			z-index: 100;
			position: relative;
			margin: -50px 0 0 0;
			padding: 15px 0 20px;
			border-radius: 6px;
			background: $white;
			box-shadow: 3px 3px 6px rgba($black, 0.15);
			color: #fff;

			// below eyecatcher
			@include media-breakpoint-up(lg) {
				margin-top: -50px;
				padding-left: 15px;
			}

			@include media-breakpoint-up(xl) {
				padding-right: 15px;
				width: 72%;
				margin-left: auto;
				margin-right: auto;
			}

			// above eyecatcher
			/* @include media-breakpoint-up(lg) {
				position: absolute;
				left: auto;
				right: auto;
				bottom: 0;
			} */


			// tabs
			div.tabs {
				position: absolute;
				top: -57px;
				left: 15px;

				@include media-breakpoint-down(md) {
					left: 30px;
				}

				ul {
					display: flex;
					align-items: flex-end;
					justify-content: flex-end;

					li {
						margin-right: 5px;

						@include media-breakpoint-down(md) {
							margin-right: 3px;
						}

						a {
							display: flex;
							align-items: center;
							height: 42px;
							padding: 0 20px;
							background-color: $secondary;
							border-radius: 6px 6px 0 0;
							color: $white;
							font-size: 14px;
							text-decoration: none;

							&:hover {
								background-color: $white;
								color: $gray;
							}

							.list-item-title {
								margin-left: 10px;
								font-weight: bold;
							}

							i {
								font-size: 18px;
							}
						}

						&:not(:first-of-type) {
							a {
								box-shadow: inset 0 -2px 3px rgba($black, 0.15);

								.list-item-title {
									@include media-breakpoint-down(md) {
										display: none;
									}
								}
							}
						}

						&:first-of-type {
							a {
								background-color: $white;
								color: $gray;
								&:hover {
									color: $primary;
								}
							}
						}
					}
				}
			}
		}
	}
}

&.accommodation-search-book {
	.mini-sab {
		display: none;
	}
}
