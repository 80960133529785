// btn
.btn {
	box-shadow: $shadow;
	font-weight: 700;
	// text-transform: uppercase;

	// btn-primary
	&.btn-primary {
		background-color: $green;
		border: 1px solid $white;
		border-radius: 3px;
		box-shadow: 0 0 0 1px $green;

		&:hover {
			background-color: $green-dark;
			border: 1px solid $white;
			border-radius: 3px;
			box-shadow: 0 0 0 1px  $green-dark;
		}
	}

	// btn-outline-primary
	// &.btn-outline-primary {
	// 	&:hover {}
	// }

	// btn-secondary
	// &.btn-secondary {
	// 	&:hover {}
	// }

	// btn-outline-secondary
	// &.btn-outline-secondary {
	// 	&:hover {}
	// }
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-primary;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}

// zoek & boek
div.book-button {
	background-color: $secondary;
	border: 1px solid $yellow;
	border-radius: $border-radius;
	margin: 0 1.5rem;
	position: relative;
	box-shadow: 0 0 0 1px  $primary,	
				0 0 0 2px  $yellow;
	transition: .2s;
	@include media-breakpoint-down(lg) {
		margin-left: auto;
	}
	@include media-breakpoint-down(sm) {
		margin-right: 10px
	}
	a {
		@extend .text-decoration-none;
		color: $white;
		padding: .75rem 1.5rem;
		font-weight: 700;
		display: inline-block;
		@include media-breakpoint-down(sm) {
			padding: 7px 1rem;
			font-size: 12px;
		}
	}

	&:hover {
		background-color: darken($secondary, 10%);
	}
}
