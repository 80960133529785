// body fixed background image
& {
	background-attachment: fixed;
	background-size: cover;
	background-position: top;
}

// container
.container {
	&.container-small {
		max-width: 540px;
	}

	&.container-medium {
		max-width: 720px;
	}

	&.container-intro {
		max-width: 650px;
		text-align: center;
	}

	&.container-outro {
		max-width: 720px;
		margin-top: 30px;
		text-align: center;
	}
}

// heading
@include headings-style {
	font-family: $font-family-secondary; // Calistoga
	color: $orange;
	font-weight: 400;
	
	&[class*=subtitle] {
		font-family: $font-family-tertiary; // Great Vibes
		color: $gray !important;
	}
}

.subtitle {
	@include headings-style {
		font-weight: 400;
		font-family: $font-family-tertiary; // Great Vibes
		color: $gray !important;
	}
}

.main {
	position: relative;
	z-index: 3;
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
	object-fit: cover;
	-o-object-fit: cover;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// transition
a, 
.btn, 
.owl-carousel .item .owl-caption .owl-btn, 
.timeslot-item .timeslot-item-navigation .timeslot-item-navigation-btn, 
.card-btn, 
.btn-down, 
.search-book .tommy-zeb-wrapper .tommy-zeb-btn, 
.owl-btn, 
.card-btn {
	transition: .2s;
}

// default-card
// .default-card {}
