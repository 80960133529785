// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $primary;
	transition: 0.5s;
	padding: 1.2rem 0 1.75rem;
	@include media-breakpoint-down(md) {
		padding: 15px 0 26px;
	}

	&.sticky {
		box-shadow: $shadow;
	}

	&::after {
		@include stars_line(bottom);
	}

	.container,
	.container-fluid {
		.container-holder {
			>.column {
				position: relative;
				align-items: center;
				
				@include media-breakpoint-up(lg) {
					padding-left: 2.5rem;
					padding-right: 2.5rem;
				}

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		z-index: 10;
		.navbar-toggler {
			color: $beige;
			font-size: 30px;
		}
	}

	// logo
	.logo {
		position: absolute;
		top: -.4rem;
		left: auto;
		z-index: 3;
		@include media-breakpoint-down(lg) {
			max-width: 190px;
			left: 80px;
			top: 8px;
		}
		@include media-breakpoint-down(sm) {
			max-width: 166px;
			left: 55px;
		}


		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
	}

	// menu
	.menu {
		justify-content: flex-end;

		.nav-link {
			color: $white;

		}

		.navbar-nav {
			> .nav-item {
				&.active {
					> .nav-link {
						color: $yellow;
					}
				}
	
			}
		}
		.dropdown-toggle {
			&::after {
				border-top: 0.3em solid;
			}
		}
		.dropdown-menu {
			border-radius: $border-radius;
			border: none;
			padding: 0;
			.nav-link {
				color: $primary;
				&:hover {
					background-color: $gray;
					.menu-title {
						color: $white;
					}
				}
			}
			.nav-item {
				&.active {
					.nav-link {
						color: $secondary;
					}
				}
			}
			
		}
		@include media-breakpoint-down(lg) {
			order: 1;
			transition: .5s;
			.navbar-nav {
				padding-top: 6rem;
				.dropdown-menu {
					.nav-link {
						padding-left: 1rem;
						padding-right: 1rem;
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {}
	}

	// site-switcher
	.site-switcher {
		.navbar-nav.language {
			.nav-item {
				.nav-link {
					color: $white;
					text-transform: uppercase;
					border: none;
					font-weight: 700;
					&::after {
						font-family: 'Font Awesome 5 Pro';
						content: '\f0ac';
						border: none;
						vertical-align: baseline;
						font-weight: 300;
					}

					&:hover {
						box-shadow: unset;
					}
				}
			}

			.dropdown-menu {
				border-radius: $border-radius;
				font-size: 14px;
				overflow: hidden;

				.dropdown-item {
					color: $primary;
					&:hover {
						background-color: $primary;
						color: $white;
					} 
				}
			}
		}
	}
}
