// footer
.footer {
    position: relative;
    background-color: $primary;
    color: $white;
    margin-top: 100px;

    .container-fluid {
		.container-holder {
			>.column {
				@include media-breakpoint-up(lg) {
					padding-left: 2.5rem;
					padding-right: 2.5rem;
				}
            }
        }
    }

    &::before {
        @include stars_line(top);
    }

    &::after {
        content: url('/images/footer-img.png');
        display: inline-block;
        position: absolute;
        bottom: 0;
        left: -9px;
    }

    .footer-outro,
    .footer-sitelinks {
        text-align: center;
    }

    .footer-outro {
        .logo {
            margin-top: -84px;
            margin-bottom: 20px;
            z-index: 2;
        }
    }

    .footer-sitelinks {
        @include media-breakpoint-down(sm) {
            margin-bottom: 140px;
        }
        .h1 {
            color: $white;
            margin-bottom: 2.5rem;
        }

        ul.vocabulary-term-list {
            margin-top: 2.5rem;
            margin-bottom: 2.5rem;
            justify-content: center;
            column-gap: 1rem;
            li {
                flex: 0 0 160px;
                max-width: 160px;
                a {
                    border: 1px solid $white;
                    border-radius: 6px;
                    text-align: center;
                    display: block;
                    color: $white;
                    padding: 11px 20px;
                    font-weight: 700;
                    font-size: 14px;
                    text-decoration: none;
                    &:hover {
                        background-color: $green;
                    }
                }

                i {
                    margin-right: 10px;
                    font-size: 18px;
                    vertical-align: middle;
                }
            }

            &.social-media {
                margin-top: 0;

                li {
                    flex: 0 0 220px;
                    max-width: 220px;

                    @include media-breakpoint-down(md) {
                        margin-bottom: 1rem;
                    }
                }
            }
        }
    }

    .footer-copyright {
        padding-bottom: 2.5rem;

        .list {
            justify-content: flex-end;
            column-gap: 1.5rem;
            @include media-breakpoint-down(sm) {
                flex-flow: column nowrap;
                align-content: flex-end;
                text-align: right;
            }

            .list-item {
                .link {
                    color: $white;
                    text-decoration: none;
                    &:hover {
                        color: $green;
                    }
                }
            }
        }
    }
}
