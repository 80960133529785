@mixin stars_line($position) {
    --w3-line-height: 22px;

    content: '';
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='14' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.15357 13.4775L7.37287 0.477539L11.5922 13.4775L0.530762 5.43807H14.215' fill='%23FAF6C5'/%3E%3C/svg%3E%0A");
    background-color: $secondary;
    background-repeat: repeat-x;
    background-position: center;
    display: block;
    width: 100%;
    height: var(--w3-line-height);
    position: absolute;
    left: 0;
    z-index: 2;
    @if ( $position == 'top' ) {
        top: calc((var(--w3-line-height) / 2) * -1);
    }
    @if ( $position == 'bottom' ) {
        bottom: calc((var(--w3-line-height) / 2) * -1);
    }
}

// add style to all headings tags
@mixin headings-style {
    @for $i from 1 through 6 {
        h#{$i}, .h#{$i} {
            @content;
        }
    }
}

// adds vendor prefixes
@mixin prefix($property, $value...) {
	$prefixes: webkit, moz, ms, o;
	@each $prefix in $prefixes {
		-#{$prefix}-#{$property}: $value;
	}
	#{$property}: $value;
}

// shape style
@mixin inner-round-shape($color, $top-percentage: 5%, $bottom-percentage: 95%, $svg-top: '/images/card-frame-top.svg', $svg-bottom: '/images/card-frame-bottom.svg') {
    @if ( $color == #FFF ) {
        background-color: unset;
        background-image: linear-gradient(transparent $top-percentage, $color $top-percentage, $color $bottom-percentage, transparent $bottom-percentage), url($svg-top), url($svg-bottom);
        background-position: center, center top, center bottom;
        background-repeat: no-repeat, no-repeat, no-repeat;
        background-size: cover, 100% auto, 100% auto;
    } @else {
        background: none;
        background-color: $color;
        @include prefix(mask-image, linear-gradient(transparent $top-percentage, $color $top-percentage, $color $bottom-percentage, transparent $bottom-percentage), url($svg-top), url($svg-bottom));
        @include prefix(mask-size, cover, 100% auto, 100% auto);
        @include prefix(mask-position, center center, center top, center bottom);
        @include prefix(mask-repeat, no-repeat, no-repeat, no-repeat);

    }
}