// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 3.5rem 0;
	}
}

// custom-section
// =========================================================================
.custom-section {
	padding: 3.5rem 0;

	.container-holder {
		background-color: rgba($section-bg, .7);
		border-radius: $border-radius;
		margin-left: 0;
		margin-right: 0;
	}

	.container-one-column {
		.container-holder {
			> .column.default {
				padding: 2.25rem 1rem;
				
				@include media-breakpoint-up(sm) {
					padding: 2.25rem 2.5rem;
				}
			}
		}
	}
}

.container-one-column {
	.container-holder {
		> .column.default {
			&.column-small {
				@include media-breakpoint-up(lg) {
					padding-left: calc((100% - 900px) / 2);
					padding-right: calc((100% - 900px) / 2);
				}
			}
			&.column-wide {
				@include media-breakpoint-up(lg) {
					padding-left: calc((100% - 1060px) / 2);
					padding-right: calc((100% - 1060px) / 2);
				}
			}
		}
	}
}

// section with stars line
.stars-section {
	position: relative;
	&::after {
		@include stars_line(bottom);
	}
}

// lead-section
// =========================================================================
.lead-section {
	padding: 3.5rem 0;

	.container-one-column {
		.container-holder {
			max-width: 750px;
			margin: 0 auto;
			text-align: center;
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}

	}
}

// content-section
// =========================================================================
.parent-section {
	filter: drop-shadow(2px 4px 6px rgba($black, 0.3));
}

.content-section {
	padding: 3.5rem 0;
	margin-top: 3.5rem;
	margin-bottom: 3.5rem;

	@include media-breakpoint-down(md) {
		.container-holder {
			margin-left: 0;
			margin-right: 0;
		}
	}
	
	@include media-breakpoint-down(sm) {
		@include inner-round-shape($white, calc(0% + 30px), calc(100% - 30px));
	}

	@include media-breakpoint-up(md) {
		@include inner-round-shape($white, calc(0% + 30px), calc(100% - 30px), '/images/blocktop.svg', '/images/blockbottom.svg');
	}

	.container-one-column,
	.container-two-columns {
		max-width: 900px;
		margin-left: auto;
		margin-right: auto;
	}

	.container-one-column {
		.container-holder {
			> .column.default {
				padding-right: 35px;
				padding-left: 35px;
				@include media-breakpoint-up(lg) {
					padding-right: 15px;
					padding-left: 15px;
				}
			}
		}
	}

	.container-two-columns {
		.container-holder {
			> .column.one {
				padding-right: 35px;
				padding-left: 35px;
				@include media-breakpoint-up(lg) {
					padding-right: 1.5rem !important;
					padding-left: 15px;
				}
			}
			> .column.two {
				padding-right: 35px;
				padding-left: 35px;
				@include media-breakpoint-up(lg) {
					padding-left: 1.5rem !important;
					padding-right: 15px;
				}
			}
		}
	}

	@include headings-style {
		&:not(.subtitle) {
			color: $primary;
		}
	}

	.lead-section {
		padding: 0;
	}

	.bundle-detail-section {
		padding-bottom: 0;
	}

}

&.home,
&.contact-page,
&.bundle-detail,
&.accommodation-detail {
	.content-section {
		margin-bottom: 8rem;
	}
}

&.bundle-detail .content-section {

	.content-section {
		padding: 0;
		margin: 0;
	}
}

&.accommodation-search-book {
	.content-section {
		margin-bottom: 0;
	}
}

// collection-section
// =========================================================================
.collection-section {
	padding: 3.5rem 0;
}

// banner-section
// =========================================================================
// .banner-section {}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	padding: 3.5rem 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	padding: 3.5rem 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	padding: 3.5rem 0;
}

// booking-section
// =========================================================================
.booking-section {
	padding: 3.5rem 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	padding: 3.5rem 0;
}
